(function ($) {
  Drupal.behaviors.basicCarouselFormatterV1 = {
    attach: function (context) {
      var $template = $('.js-basic-carousel-formatter--v1', context);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $template.each(function () {
        var thisFormatter = $(this);
        var carousel = thisFormatter.find('.js-basic-carousel');
        var arrowsDiv = thisFormatter.find('.carousel-controls');
        var dotsDiv = thisFormatter.find('.carousel-dots');
        var basicSlide = '.js-basic-carousel__slide';
        // autoplay settings
        var autoplay = carousel.attr('data-slides-autoplay');

        if (typeof autoplay === 'undefined') {
          autoplay = false;
        } else {
          autoplay = true;
        }

        // slides to show
        var slidesShowCountSmall = carousel.data('slides-show-small');
        var slidesShowCountMedium = carousel.data('slides-show-medium');
        var slidesShowCountLarge = carousel.data('slides-show-large');
        var centerMode = false;
        var centerPadding = '0';

        if (carousel.data('slides-center_mode') && slidesShowCountSmall == 1) {
          centerMode = true;
          centerPadding = '50px';
          carousel.addClass('center-mode-carousel');
        }

        var settings = {
          arrows: true,
          appendArrows: arrowsDiv,
          dots: true,
          appendDots: dotsDiv,
          slide: basicSlide,
          infinite: true,
          slidesToShow: slidesShowCountLarge,
          slidesToScroll: slidesShowCountLarge,
          autoplay: autoplay,
          // adaptiveHeight: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: slidesShowCountMedium,
                slidesToScroll: slidesShowCountMedium
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: slidesShowCountSmall,
                slidesToScroll: slidesShowCountSmall,
                centerMode: centerMode,
                centerPadding: centerPadding
              }
            }
          ]
        };

        // Init this carousel with our settings
        carousel.slick(settings);
      });
    }
  };
})(jQuery);
